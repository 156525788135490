<template> 
    <v-app style="overflow-y:hidden">
      <Header class="header-bar" v-if="!currentUser || !currentUser.isStaff" :hideDrawer="hideDrawer"></Header>
      <HeaderStaff class="staff-header-bar" v-if="currentUser && currentUser.isStaff && isStaffPath" :currentUser="currentUser"></HeaderStaff>
      <router-view/>
      <FooterNavigation class="is-mobile" v-if="currentUser && !currentUser.isStaff && !isStaffPath"></FooterNavigation>
      <FooterNavigationDesktop class="is-desktop footer-navigation-desktop" v-if="!currentUser || !isStaffPath"></FooterNavigationDesktop>
      <FooterStaff v-if="currentUser && currentUser.isStaff && isStaffPath"></FooterStaff>
    </v-app>
</template>

<script>
import Header from './components/Header.vue'
import HeaderStaff from './components/HeaderStaff.vue'
import FooterNavigation from './components/FooterNavigation.vue'
import FooterNavigationDesktop from './components/FooterNavigationDesktop.vue'
import FooterStaff from '/src/components/FooterStaff.vue'
import { mapGetters } from "vuex";
import _ from 'lodash';

export default {
  name: 'App',
  components: {
    Header,
    HeaderStaff,
    FooterNavigation,
    FooterNavigationDesktop,
    FooterStaff
  },

  data(){
    return {
      showfooter: false,
    }
  },

  computed: {
      ...mapGetters(["currentUser", "processing", "loginError"]),

      isLoginPage() {
        return this.$route.path.includes("login");
      },
      isStaffPath() {
        return this.$route.path.includes("staff");
      },
      hideDrawer() {
        return this.$route.path.includes("staff");
      }
  },
  watch:{
  }
}
</script>

<style>
#app {
  background-color: transparent;
}
</style>
