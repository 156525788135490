import { isAuthGuardActive, openRoadApiUrl, version } from '../../constants/config'
import { setCurrentUser, getCurrentUser, getCurrentUserDetail } from '../../utils'
import axios from 'axios'
export default {
  state: {
    currentUser: isAuthGuardActive ? getCurrentUser() : null,
    loginError: null,
    processing: false,
    forgotMailSuccess: null,
    resetPasswordSuccess: null
  },
  getters: {
    currentUser: state => state.currentUser,
    currentUserDetail: state => state.currentUserDetail,
    processing: state => state.processing,
    loginError: state => state.loginError,
    forgotMailSuccess: state => state.forgotMailSuccess,
    resetPasswordSuccess: state => state.resetPasswordSuccess,
  },
  mutations: {
    setUser(state, payload) {
      state.currentUser = payload
      state.processing = false
      state.loginError = null
    },
    setLogout(state) {
      state.currentUser = null
      state.processing = false
      state.loginError = null
    },
    setProcessing(state, payload) {
      state.processing = payload
      state.loginError = null
    },
    setError(state, payload) {
      state.loginError = payload
      state.currentUser = null
      state.processing = false
    },
    setForgotMailSuccess(state) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.forgotMailSuccess = true
    },
    setResetPasswordSuccess(state) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.resetPasswordSuccess = true
    },
    clearError(state) {
      state.loginError = null
    }
  },
  actions: {
    login({ commit }, payload) {
        commit('clearError')
        commit('setProcessing', true)
  
        let form = {
          userNameOrEmailAddress: payload.email,
          password: payload.password,
          rememberClient: true,
        };
        let url = openRoadApiUrl + "/api/tokenauth/authenticate";
        axios.post(url, form)      
        .then(
          response => {         
            const item = { uid: response.data.result.userId, 
                           accessToken: response.data.result.accessToken,
                           refreshToken: response.data.refreshToken,
                           expireInSeconds: response.data.expireInSeconds,
                           details: {},
            }
            setCurrentUser(item)
            commit('setUser', item)
          },
          err => {
            setCurrentUser(null);
            const error = { message: err.response.data.error.message, 
                            details: err.response.data.error.details }
            commit('setError', error)
            setTimeout(() => {
              commit('clearError')
            }, 3000)
          }
        )
      },
    forgotPassword({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)

      let form = {
        email: payload.email,
      };
      let url = openRoadApiUrl + "/api/services/" + version + "/user/me/forgotpassword";
      axios.post(url, form)      
      .then(
        response => {  
            commit('clearError')
            commit('setForgotMailSuccess')          
        },
        err => {
            commit('setError', err.message)
        }
      )
    },
    resetPassword({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)
    },


    signOut({ commit }) {        
        setCurrentUser(null);
        commit('setLogout');
    }
  }
}
