<template>
<div>
  <v-container>
    <v-card app>
    <div class="row">
          <v-app-bar
            dark
            app
            class="header-bar"
          >
            <div class="col-10 col-md-5 pl-0 pt-5">
              <router-link to="/landing">
                <img class="logo" src="../assets/img/OpenRoadAutoStorage-Horizontal-white.png" />
              </router-link>
            </div>
            <div class="col-2 col-md-7">
              <FooterNavigation class="is-desktop" v-if="currentUser && !currentUser.isStaff"></FooterNavigation>
              <div class="navigation-icon-position"><v-app-bar-nav-icon @click.stop="drawer = !drawer" class="is-mobile" v-if="!hideDrawer"></v-app-bar-nav-icon></div>
            </div>
            
          </v-app-bar>
    </div>

    <v-navigation-drawer
        v-model="drawer"
        fixed
        right
        temporary
        color="black"
        dark
    >    
    <v-btn 
      fixed
      right
      class="drawer-btn"
      @click="drawer=false"
    >
      <i class="fa fa-times drawer-icon" aria-hidden="true"></i>
    </v-btn>

    <v-list
        nav
        class="drawer-list"
    >       
    <v-list-item-group
        v-model="group"       
    >
        <div class="row">
          <div class="col-12">
            <a href="tel:+6016-316 7386"  class="external-link">Call Us </a>
          </div>
          <div class="col-12">
            <a href="/cctv" class="external-link mt-2"> Install CCTV App </a>    
          </div>
          <div class="col-12">
            <a href="http://openroadasia.com/legal-notice" target="_blank" class="external-link mt-2"> Legal Notice </a>    
          </div>
          <div class="col-12">
            <a href="http://openroadasia.com/" target="_blank" class="external-link"> OPenRoadAsia.com </a>   
          </div>
        </div>     
          
    </v-list-item-group>
      </v-list>
      
      <v-card-text class="drawer-copyright-position">
      <span class="drawer-copyright">© 2021 OPen Road Auto Storage</span>
      </v-card-text>

    </v-navigation-drawer>
    </v-card>
     </v-container>
  </div> 
</template>

<script>
import FooterNavigation from '../components/FooterNavigation.vue'
import { mapGetters } from "vuex";

  export default {
    props: ['hideDrawer'],
    components: {
    FooterNavigation,
    },
    data: () => ({
      drawer: false,
      group: null,
    }),
    methods: {			
		},


    watch: {
      group () {
        this.drawer = false
      },
    },

    computed: {
      ...mapGetters(["currentUser", "processing", "loginError"])
  },
  }
</script>