<template>
<div>
  <v-container>
    <v-card app>
    <div class="row">
          <v-app-bar
            dark
            app
            class="staff-header-bar"
          >
            <div class="col-2 col-md-2 pl-0 pt-10">
              <img class="staff-profile" src="../assets/img/profile_pic.png" />
              <!-- <img class="staff-profile" :src='profilePic'> //phase2 -->
            </div>
            <div class="col-8 col-md-8 pt-12">
                <p class="staff-header-name pb-0 mb-0">{{fullName}}</p>
                <p class="color-theme-2 staff-header-position pt-0 mt-0 camel-case">{{roleName}}</p>
            </div>
            <div class="col-2 col-md-2 staff-logout pt-10">
              <img @click="logout" src="../assets/img/logout.png" />
            </div>
            
          </v-app-bar>
    </div>

    </v-card>
     </v-container>
  </div> 
</template>

<script>
import { mapActions } from "vuex";
import { openRoadApiUrl, version, uploadPath, uploadTypeProfile} from "/src/constants/config";

export default {
    props: ['currentUser'],
    components: {},

    data() {
        return {
            items: [],
            profilePic: "",
            fullName: this.currentUser ? this.currentUser.details.name : "",
            roleName: this.currentUser && this.currentUser.details.roleNames.length > 0 ? this.currentUser.details.roleNames[0].toLowerCase() : "",
        }
    },

    mounted(){
        //phase2
        this.profilePic = this.currentUser && this.currentUser.details && this.currentUser.details.profilePhoto != ""
                          ? uploadPath + "/" + uploadTypeProfile + "/" + this.currentUser.details.profilePhoto 
                          : "";
    },

    methods: {
        ...mapActions(["signOut"]),
        logout() {
            this.signOut().then(() => {
            this.$router.push("/staff/login");
            });
        },			
	},

    watch: {
    },

    computed: {
  },
  }
</script>