export const isAuthGuardActive = true
// export const openRoadApiUrl = "https://stagapi.openroadautostorage.com";
export const openRoadApiUrl = "https://api.openroadautostorage.com";
export const version = 'v1';
// export const uploadPath = "https://stagapi.openroadautostorage.com/uploads";
export const uploadPath = "https://api.openroadautostorage.com/uploads";
export const uploadTypeCar = "car";
export const uploadTypeActivity = "activity";
export const uploadTypeCarDocument = "cardocument";
export const uploadTypeGeneral = "general";
export const uploadTypeProfile = "profile";
export const activityCategories = 
[ { text: 'Show All', activityCategoryId: 'All', src: require('/src/assets/img/Show All.png') },
{ text: 'Start Engine', activityCategoryId: 'EN', src: require('/src/assets/img/Start Engine.png') },
{ text: 'Car Wash', activityCategoryId: 'WC', src: require('/src/assets/img/activity_car_wash.png')},
{ text: 'Inflate Tyre', activityCategoryId: 'IT', src: require('/src/assets/img/Inflate Tyre.png') },
{ text: 'Hybrid Charge', activityCategoryId: 'HC', src: require('/src/assets/img/Hybrid Charge.png') },
{ text: 'Replace Battery', activityCategoryId: 'XB', src: require('/src/assets/img/Replace Battery.png')},
{ text: 'Refuel Petrol', activityCategoryId: 'RP', src: require('/src/assets/img/Refuel Petrol.png') },
{ text: 'Replace Tyre', activityCategoryId: 'XT', src: require('/src/assets/img/Replace Tyre.png') },
{ text: 'Other Activities', activityCategoryId: 'Others', src: require('/src/assets/img/Others.png') }];

export const statusPending = "Pending";
export const statusInProgress = "InProgress";
export const statusCompleted = "Completed";