<template>
<div class="footer-navigation-desktop drawer-copyright-position">
    <v-row>
        <v-col md="4" sm="4" class="static-navigation-copyright"> 
        <span class="drawer-copyright">© 2021 OPen Road Auto Storage</span>
        </v-col>
         <v-col md="8" sm="8" class="static-navigation-position"> 
           <div>
              <a href="tel:+6016-316 7386"  class="link-deco drawer-copyright">Call Us </a>
              <span class="drawer-copyright drawer-copyright-separator">.</span>               
              <router-link to="/cctv" class="link-deco drawer-copyright"> Install CCTV App </router-link> 
              <span class="drawer-copyright drawer-copyright-separator">.</span>
              <a href="http://openroadasia.com/legal-notice" target="_blank" class="link-deco drawer-copyright"> Legal Notice </a>   
              <span class="drawer-copyright drawer-copyright-separator">.</span>
              <a href="http://openroadasia.com/" target="_blank" class="link-deco drawer-copyright"> OPenRoadAsia.com </a>          
           </div>
        </v-col>
    </v-row>
</div>
</template>

<script>
  export default {
    props: [],
    data() {
			return {
			};
		},

    methods: {
		},

    watch: {
    },
  }
</script>