let render = () => {
  import("./assets/css/sass/main.scss")
};

render();

import Vue from 'vue'
import App from './App.vue'
import vuetify from './vuetify' // path to vuetify export
import router from './router'
import store from './store'
import Notifications from './components/Common/Notification'

import moment from 'moment'
Vue.config.productionTip = false
Vue.use(Notifications);

Vue.filter('formatDate', function(value) {
    return value ? moment(String(value)).format('DD / MM / YYYY') : "-";
})

Vue.filter('formatData', function(value) {
  return value ? value : "-";
})

Vue.filter('formatAmount', function(value) {
  return value ? value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
})

import VueQrcodeReader from "vue-qrcode-reader";
Vue.use(VueQrcodeReader);

import ScrollLoader from 'vue-scroll-loader';
Vue.use(ScrollLoader);

import VueAgile from 'vue-agile'
Vue.use(VueAgile)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
