<template>
  <v-bottom-navigation 
      dark
      class="bottom-navigation-bar"
      app
    >
    <router-link
        to="/mycars"
    >
    <v-btn value="mycars" @click="toggle('car')" class="icon-spacing bottom-navigation-mycars" active-class="icon-spacing bottom-navigation-mycars-active bottom-navigation-icon-active">
      <img class="is-mobile" :src="srcMyCarsMobile" />
      <img class="is-desktop" :src="srcMyCarsDesktop" />
    </v-btn>
    </router-link>

    <router-link
        to="/bills"
    >
    <v-btn value="bills"  @click="toggle('bill')" class="icon-spacing" active-class="icon-spacing bottom-navigation-icon-active">
      <img class="is-mobile" :src="srcBillMobile" />
      <img class="is-desktop" :src="srcBillDesktop" />
    </v-btn>
  </router-link>

    <router-link
        to="/profile"
    >
    <v-btn value="profile"  @click="toggle('profile')"  class="icon-spacing"  active-class="icon-spacing bottom-navigation-icon-active">
      <img class="is-mobile" :src="srcProfileMobile" />
      <img class="is-desktop" :src="srcProfileDesktop" />
    </v-btn>
    </router-link>
  </v-bottom-navigation>
</template>

<script>
  export default {
    props: [],
    data() {
			return {
        isCar: false,
        isBill: false,
        isProfile: false,
			};
		},

    mounted(){
      this.setMenuHighlight();         
    },

    methods: {
			toggle(value) {
        this.isCar = value =='car';
        this.isBill = value =='bill';
        this.isProfile = value =='profile';
      },

      setMenuHighlight(){
        let href = window.location.href;   
        this.isCar = href.indexOf("mycars") != -1 || href.indexOf("mycar") != -1;
        this.isBill = href.indexOf("bills") != -1;
        this.isProfile = href.indexOf("profile") != -1;
      }
		},

    watch: {
      $route(val){
        this.setMenuHighlight();
      }
    },

    computed: {
      srcMyCarsMobile(){
        return this.isCar ? require('../assets/img/mycars_active.png') : require('../assets/img/mycars.png');
      },
      srcMyCarsDesktop(){
        return this.isCar ? require('../assets/img/mycars_active_big.png') : require('../assets/img/mycars_big.png');
      },
      srcBillMobile(){
        return this.isBill ? require('../assets/img/bill_active.png') : require('../assets/img/bill.png');
      },
      srcBillDesktop(){
        return this.isBill ? require('../assets/img/bill_active_big.png') : require('../assets/img/bill_big.png');
      },
      srcProfileMobile(){
        return this.isProfile ? require('../assets/img/profile_active.png') : require('../assets/img/profile.png');
      },
      srcProfileDesktop(){
        return this.isProfile ? require('../assets/img/profile_active_big.png') : require('../assets/img/profile_big.png');
      },
    }
  }
</script>