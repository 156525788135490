import Vue from "vue";
import VueRouter from "vue-router";
import AuthGuard from "./utils/auth.guard";
import { adminRoot } from "./constants/config";
import { UserRole } from "./utils/auth.roles";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/landing",
    // component: () => import(/* webpackChunkName: "login" */ "./views/LoginPage"),
    // meta: { loginRequired: true, roles: [UserRole.Customer] },
  },
  {
    path: "/landing",
    component: () => import(/* webpackChunkName: "login" */ "./views/LandingPage"),
  },
  {
    path: "/login",
    component: () => import(/* webpackChunkName: "login" */ "./views/LoginPage"),
  },
  {
    path: "/forgotpassword",
    component: () => import(/* webpackChunkName: "forgotpassword" */ "./views/ForgotPassword"),
  },
  {
    path: "/reset-password",
    component: () => import(/* webpackChunkName: "forgotpassword" */ "./views/ResetPassword"),
  },
  {
    path: "/changepassword",
    component: () => import(/* webpackChunkName: "forgotpassword" */ "./views/ChangePassword"),
    meta: { loginRequired: true, roles: [UserRole.Customer] },
  },
  {
    path: "/mycars",
    component: () => import(/* webpackChunkName: "mycars" */ "./views/MyCars"),
    meta: { loginRequired: true, roles: [UserRole.Customer]},
  },
  {
    path: "/mycar/item/detail",
    component: () => import(/* webpackChunkName: "mycardetails" */ "./views/MyCarDetails"),
    meta: { loginRequired: true, roles: [UserRole.Customer] },
  },
  {
    path: "/bills",
    component: () => import(/* webpackChunkName: "bills" */ "./views/Bills"),
    meta: { loginRequired: true, roles: [UserRole.Customer] },
  },
  {
    path: "/profile",
    component: () => import(/* webpackChunkName: "profile" */ "./views/Profile"),
    meta: { loginRequired: true, roles: [UserRole.Customer] },
  },
  {
    path: '/cctv',
    component: () => import(/* webpackChunkName: "cctv" */ "./views/InstallCCTVApp"),
  },
  {
    path: "/staff",
    redirect: "/staff/all-tasks",
    meta: { loginRequired: true, isStaff: true, roles: [UserRole.Staff] },    
  },
  {
    path: "/staff/login",
    component: () => import(/* webpackChunkName: "login" */ "./views/staff/LoginPage"),
    meta: { isStaff: true, isLoginPage: true, roles: [UserRole.Staff]},
  },
  {
    path: "/staff/all-tasks",
    component: () => import(/* webpackChunkName: "profile" */ "./views/staff/AllTasks"),
    meta: { loginRequired: true, isStaff: true, roles: [UserRole.Staff] },
  },
  {
    path: "/staff/my-tasks",
    component: () => import(/* webpackChunkName: "profile" */ "./views/staff/MyTasks"),
    meta: { loginRequired: true, isStaff: true, roles: [UserRole.Staff] },
  },
  {
    path: "/staff/activity-action",
    component: () => import(/* webpackChunkName: "profile" */ "./views/staff/ActivityActions"),
    meta: { loginRequired: true, isStaff: true, roles: [UserRole.Staff] },
  },
  {
    path: "/staff/check-in-out",
    component: () => import(/* webpackChunkName: "profile" */ "./views/staff/CheckInOut"),
    meta: { loginRequired: true, isStaff: true, roles: [UserRole.Staff] },
  },
  {
    path: "*",
    component: () => import(/* webpackChunkName: "profile" */ "./views/Maintenance"),
  },
];

const router = new VueRouter({
  linkActiveClass: "active",
  routes,
  mode: "history",
});
router.beforeEach(AuthGuard);
export default router;
