import { isAuthGuardActive } from '../constants/config'
import { setCurrentUser, getCurrentUser } from '.'
import _ from 'lodash';

export default (to, from, next) => {
  if (to.matched.some(record => record.meta.loginRequired)) {
    if (isAuthGuardActive) {
      const user = getCurrentUser();
      if (user && user.details) {
        const roleArrayHierarchic = to.matched.filter(x => x.meta.roles).map(x => x.meta.roles);
        let isMatch = roleArrayHierarchic.every(x => (_.intersection(x, user.details.roleNames)).length == x.length);

        // Do checking for weird staff role names
        if(!isMatch) {
          roleArrayHierarchic.forEach(function(role){
            if(!isMatch) isMatch = _.filter(user.details.permissionNames, function(p){ return p.includes(role) }).length > 0;
          });
        }
        console.log(isMatch);
        if (isMatch) {
          next();
        } 
        else {
          if(to.matched.some(record => record.meta.isStaff)){
            next('/staff/login')
          }
          else{
            next('/login')
          }
        }
      } 
      else {
        setCurrentUser(null);
        if(to.matched.some(record => record.meta.isStaff)){
          next('/staff/login')
        }
        else{
          next('/login')
        }        
      }
    } else {
      next();
    }
  } 
  else{
    next();
  }
}
