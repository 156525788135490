import { openRoadApiUrl, version } from '/src/constants/config'
import axios from 'axios'
import _ from 'lodash';

export const getCurrentUser = () => {
  let user = null;
  try {
    user = localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null;
  } catch (error) {
    console.log(">>>> src/utils/index.js : getCurrentUser -> error", error)
    user = null;
  }
  return user;
}

export const setCurrentUser = (user) => {
  try {
    if (user) {      
      let apiHeaders = { headers: {Authorization: 'Bearer ' + user.accessToken } };
      // get user detail
      let url = openRoadApiUrl + "/api/services/" + version + "/user/me";
      axios.get(url, apiHeaders)
      .then(
        response => { 
          user.details = response.data.result; 
          let role = response.data 
                   ? _.find(response.data.result.permissionNames, function(o){return o.toLowerCase().includes("staff"); })
                   : null       
          user.isStaff = role ? true : false;
          localStorage.setItem('user', JSON.stringify(user));          
        },
        err => {
          console.log(err);
        }
      )  
    } else {
      localStorage.removeItem('user');
    }
  } catch (error) {
    console.log(">>>> src/utils/index.js : setCurrentUser -> error", error)
  }
}


