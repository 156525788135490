<template>
  <v-bottom-navigation 
      dark
      class="bottom-navigation-bar"
      app
    >
    <router-link
        to="/staff/all-tasks?path=allTasks"
    >
    <v-btn value="allTasks" @click="toggle('allTasks')" class="staff-icon-spacing" active-class="staff-icon-spacing bottom-navigation-icon-active">
      <img class="is-mobile" :src="srcAllTasksMobile" />
    </v-btn>
    </router-link>

    <router-link
        to="/staff/my-tasks?path=myTasks"
    >
    <v-btn value="myTasks"  @click="toggle('myTasks')" class="staff-icon-spacing" active-class="staff-icon-spacing bottom-navigation-icon-active">
      <img class="is-mobile" :src="srcMyTasksMobile" />
    </v-btn>
  </router-link>

    <router-link
        to="/staff/check-in-out?path=checkinout"
    >
    <v-btn value="checkInOut"  @click="toggle('checkInOut')"  class="staff-icon-spacing check-in-out-icon"  active-class="staff-icon-spacing bottom-navigation-icon-active check-in-out-icon">
      <img class="is-mobile" :src="srcCheckInOutMobile" />
    </v-btn>
    </router-link>
  </v-bottom-navigation>
</template>

<script>
  export default {
    props: [],
    data() {
        return {
            isAllTasks: true,
            isMyTasks: false,
            isCheckInOut: false,
			};
    },

    mounted(){
      let uri = window.location.search.substring(1); 
      let params = new URLSearchParams(uri);
      let path = params.get("path");
      
      this.isMyTasks = path == "myTasks";
      this.isCheckInOut = path == "checkinout";
      this.isAllTasks = path == "allTasks" || ( !this.isMyTasks && !this.isCheckInOut );
    },

    methods: {
        toggle(value) {
            this.isAllTasks = value =='allTasks';
            this.isMyTasks = value =='myTasks';
            this.isCheckInOut = value =='checkInOut';
            }
		},

    watch: {
    },

    computed: {
      srcAllTasksMobile(){
        return this.isAllTasks ? require('/src/assets/img/all_tasks_active.png') : require('/src/assets/img/all_tasks.png');
      },
      srcMyTasksMobile(){
        return this.isMyTasks ? require('/src/assets/img/my_tasks_active.png') : require('/src/assets/img/my_tasks.png');
      },
      srcCheckInOutMobile(){
        return this.isCheckInOut ? require('/src/assets/img/check_in_out_active.png') : require('/src/assets/img/check_in_out.png');
      },
    }
  }
</script>